<template>
  <SimpleModal
    :heading="$t('socialProjects.yourDonations')"
    :loading="isLoading"
    @close="$emit('close')"
    modal-style="width: 550px"
  >
    <div>
      <!-- Items -->
      <div style="max-height: 60vh; overflow-x: hidden; overflow-y: auto">
        <div
          v-for="item in items"
          :key="item.id"
          class="row pb-4 mt-4"
          style="border-bottom: 1px solid #d4d4d4"
        >
          <!-- Left (image) -->
          <div class="col-3 d-none d-md-block">
            <img
              :src="item.image"
              style="
                border-radius: 5px;
                object-fit: cover;
                width: 100%;
                height: 100px;
              "
            />
          </div>

          <!-- Middle (text) -->
          <div class="col">
            <!-- Title -->
            <h3
              class="mb-2"
              style="color: #e58413; font-weight: 500; font-size: 18px"
            >
              {{ item.title }}
            </h3>

            <!-- Description -->
            <p class="mb-2">{{ item.description }}</p>

            <!-- Date -->
            <div class="text-secondary" style="font-size: 14px">
              {{ item.date }}
            </div>
          </div>

          <!-- Right (price amount) -->
          <div class="col-12 col-sm-3 d-flex">
            <div
              class="my-auto text-orange"
              style="font-weight: bold; font-size: 20px"
            >
              {{ item.amount | EUR }}
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="row mt-3">
        <div class="col">
          <ButtonElement orange-secondary block @click="$emit('close')">
            {{ $t('socialProjects.cancel') }}
          </ButtonElement>
        </div>
        <div class="col">
          <ButtonElement orange block>
            {{ $t('socialProjects.donateAgain') }}
          </ButtonElement>
        </div>
      </div>
    </div>
  </SimpleModal>
</template>

<script>
import SimpleModal from '@/components/Modal/SimpleModal';

export default {
  data() {
    return {
      items: [],
      isLoading: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      setTimeout(() => {
        this.items = [
          {
            id: 1,
            title: 'Hunger Is On The Rise',
            description:
              'Access to sufficient amounts of safe and nutritious food...',
            date: '4.2.2021. 10:53',
            amount: 145,
            image:
              'https://drmarkpbarry.files.wordpress.com/2013/07/123080hungry1.png',
          },
          {
            id: 2,
            title: 'Support Elderly COVID 19',
            description: "While you're not able to see or tou...",
            date: '4.2.2021. 10:53',
            amount: 100,
            image:
              'https://media.istockphoto.com/photos/vertical-portrait-of-happy-elderly-woman-with-nurse-at-home-picture-id1181670585?k=6&m=1181670585&s=170667a&w=0&h=2FG66_eX0YrW876xByXf9XdcLShrfWil2BNv1BvQu5Y=',
          },
          {
            id: 3,
            title: 'Promoting Social Enterprise',
            description: 'Our company is actively supporting...',
            date: '4.2.2021. 10:53',
            amount: 220,
            image:
              'https://uia-initiative.eu/sites/default/files/Whole%20Room_Illustration%20principale.jpg',
          },
          {
            id: 4,
            title: 'Promoting Social Enterprise',
            description: 'Our company is actively supporting...',
            date: '4.2.2021. 10:53',
            amount: 220,
            image:
              'https://uia-initiative.eu/sites/default/files/Whole%20Room_Illustration%20principale.jpg',
          },
          {
            id: 5,
            title: 'Promoting Social Enterprise',
            description: 'Our company is actively supporting...',
            date: '4.2.2021. 10:53',
            amount: 220,
            image:
              'https://uia-initiative.eu/sites/default/files/Whole%20Room_Illustration%20principale.jpg',
          },
          {
            id: 6,
            title: 'Promoting Social Enterprise',
            description: 'Our company is actively supporting...',
            date: '4.2.2021. 10:53',
            amount: 220,
            image:
              'https://uia-initiative.eu/sites/default/files/Whole%20Room_Illustration%20principale.jpg',
          },
        ];
        this.isLoading = false;
      }, 500);
    },
  },
  components: {
    SimpleModal,
  },
};
</script>

<style lang="scss" scoped></style>
